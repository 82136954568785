import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { isToday } from 'date-fns'; // Import date-fns for date comparison
import {
  ReservationCollectionListener,
} from '../../../../services/pms';
import Header from '../../../Common/Header/Header';
import SideMenu from '../../../Common/Sidemenu/Sidemenu';
import { useDispatch, useSelector } from 'react-redux';
import PageNamecard from '../../../Common/PageNameCard/PageNameCard';
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  Collections,
} from '../../../../config/constants';
import CustomAlert from '../../../Common/CustomAlert/CustomAlert';
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization';
import { AuthContext } from '../../../../Router/AuthRouteProvider';
const TodaysArrival = () => {
  const { hotelId, userInfo } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [translateTextI18N] = useCustomI18NTranslatorHook();
  const reservations = useSelector((state) => state.reservations); // Move outside
  const dispatch = useDispatch();
  useEffect(() => {
    ReservationCollectionListener(hotelId, dispatch);
  }, [dispatch, hotelId]);
  const todayReservations = reservations.filter((reservation) =>
    isToday(new Date(reservation.HotelReservation.ResGlobalInfo.TimeSpan.$.Start))
  );
  const columns = [
    {
      title: translateTextI18N('Reservation Id'),
      dataIndex: 'reservationId',
      render: (text, row) => `${row.HotelReservation.UniqueID.$.ID}`,
      width: 150,
    },
    {
      title: translateTextI18N('Booking Reference'),
      dataIndex: 'bookingReference',
      render: (text, row) => `${row.HotelReservation.ResGlobalInfo.BasicPropertyInfo.$.HotelCode}`,
      width: 150,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      render: (text, row) => `${row.HotelReservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.PersonName.GivenName} ${row.HotelReservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.PersonName.Surname}`,
      width: 200,
    },
    {
      title: translateTextI18N('Email'),
      dataIndex: 'email',
      render: (text, row) => `${row.HotelReservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Email}`,
      width: 220,
    },
    {
      title: translateTextI18N('Check-In Date'),
      dataIndex: 'checkinDate',
      render: (text, row) => `${row.HotelReservation.ResGlobalInfo.TimeSpan.$.Start}`,
      width: 150,
    },
    {
      title: translateTextI18N('Phone no'),
      dataIndex: 'phoneNo',
      render: (text, row) => `${row.HotelReservation.ResGuests.ResGuest.Profiles.ProfileInfo.Profile.Customer.Telephone.$.PhoneNumber}`,
      width: 150,
    },
  ];
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Today&apos;s Arrivals'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Today\'s Arrivals',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={todayReservations}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TodaysArrival;