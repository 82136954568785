import React from 'react'
import { Document, Page, View, Image, Text } from '@react-pdf/renderer'
import styles from './index'
import { Table } from 'antd'
import Html from 'react-pdf-html'
var ReactDOMServer = require('react-dom/server')

const ExportPdf = ({ column, data, profileData }) => {
  const renderPdf = () => {
    let result = ReactDOMServer.renderToString(
      <Table
        className='pdf'
        pagination={false}
        dataSource={data}
        columns={column}
        locale={{ emptyText: 'No data found' }}
      />
    )
    let withStyle =
      `<style>
      .pdf .ant-table table {
        margin: 8;
        width: 770;
      }
      .pdf .ant-table table tbody tr:nth-child(odd){
        background: #d7d5d7;
        font-size: 7;
      }
      .pdf .ant-table table tbody tr:nth-child(even){
        background: #e2e0e2;
        font-size: 7;
      }
      .pdf .ant-table table thead tr th {
        color: #363536;
        background: #9f9d9f;
        font-weight: bold;
        font-size: 8;
      }
      .pdf .ant-table table tr th,
      .pdf .ant-table table tr td {
        padding: 5;
      }</style>` + result
    return <Html>{withStyle}</Html>
  }

  return (
    <Document>
      <Page size='A4' style={styles.page} orientation='landscape'>
        <View style={styles.flex_container} fixed>
          <Image
            src={profileData?.hotelInfo?.hotelLogo}
            style={styles.profile_img}
          />
          <View style={styles.flex_items}>
            <Text style={styles.profile_title}>
              {profileData?.hotelInfo.hotelName}
            </Text>
            <Text style={styles.profile_sub_title}>
              {profileData?.hotelInfo.address}
            </Text>
          </View>
        </View>
        <View style={styles.contentSection}>
          <Text style={styles.reportTitleSection}>
            {profileData?.reportTitle}
          </Text>
          <View>{renderPdf()}</View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}

export default ExportPdf
