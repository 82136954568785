import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { DatePicker, Table, Button, message } from 'antd';
import Header from '../../../Common/Header/Header';
import SideMenu from '../../../Common/Sidemenu/Sidemenu';
import { useDispatch, useSelector } from 'react-redux';
import PageNamecard from '../../../Common/PageNameCard/PageNameCard';
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  Collections,
  APIs,
} from '../../../../config/constants';
import CustomAlert from '../../../Common/CustomAlert/CustomAlert';
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization';
import { AuthContext } from '../../../../Router/AuthRouteProvider';
import axios from 'axios';
const PMSrooms = () => {
  const [roomData, setRoomData] = useState(null);
  const { hotelId, userInfo } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [translateTextI18N] = useCustomI18NTranslatorHook();
  const reservations = useSelector(state => state.reservations); // Move outside
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateFormatList = 'YYYY-MM-DD';
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const startDateFormatted = startDate ? formatDate(startDate._d) : null;
  const endDateFormatted = endDate ? formatDate(endDate._d) : null;
  const soapRequest = useMemo(() => {
    if (!startDateFormatted || !endDateFormatted) return null;
    return `
      <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
        <soap:Header>
          <OGHeader transactionID="000099" timeStamp="2008-02-20T09:34:22.9468750-05:00" xmlns="http://webservices.micros.com/og/4.3/Core/">
            <Origin entityID="KIOSK" systemType="KIOSK"/>
            <Destination entityID="TI" systemType="PMS"/>
            <Authentication>
              <UserCredentials>
                <UserName>KIOSK</UserName>
                <UserPassword>$$$KIOSK$$</UserPassword>
                <Domain>GERO</Domain>
              </UserCredentials>
            </Authentication>
          </OGHeader>
        </soap:Header>
        <soap:Body>
          <FetchRoomStatusRequest RoomType="SKI" xmlns="http://webservices.micros.com/og/4.3/ResvAdvanced/" xmlns:c="http://webservices.micros.com/og/4.3/Common/" xmlns:hc="http://webservices.micros.com/og/4.3/HotelCommon/" xmlns:n="http://webservices.micros.com/og/4.3/Name/" xmlns:r="http://webservices.micros.com/og/4.3/Reservation/">
            <HotelReference chainCode="CHA" hotelCode="GERO"/>
            <StartDate>${startDateFormatted}</StartDate>
            <EndDate>${endDateFormatted}</EndDate>
          </FetchRoomStatusRequest>
        </soap:Body>
      </soap:Envelope>
    `;
  }, [startDateFormatted, endDateFormatted]);
  const fetchRoomData = useCallback(async () => {
    if (!soapRequest) return;
    setShowLoader(true);
    try {
      const response = await fetch(
        'http://localhost:8080/api/v1/pms/fetchRoomStatus',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/xml;charset=UTF-8',
          },
          body: soapRequest,
        }
      );
      const responseBody = await response.json();
      console.log('Response:', responseBody);
      setRoomData(responseBody);
      setSuccessMessage('Data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Failed to fetch data');
    } finally {
      setShowLoader(false);
    }
  }, [soapRequest]);
  useEffect(() => {
    if (startDate && endDate) {
      fetchRoomData();
    }
  }, [fetchRoomData, startDate, endDate]);
  const columns = [
    {
      title: translateTextI18N('Floor'),
      dataIndex: '_Floor',
      width: 100,
    },
    {
      title: translateTextI18N('Room Number'),
      dataIndex: '_RoomNumber',
      width: 100,
    },
    {
      title: translateTextI18N('Room Status'),
      dataIndex: '_RoomStatus',
      width: 100,
    },
    {
      title: translateTextI18N('Room Type'),
      dataIndex: '_RoomType',
      width: 100,
    },
    {
      title: translateTextI18N('Room Description'),
      dataIndex: '_RoomDescription',
      width: 100,
    },
    {
      title: translateTextI18N('Room Class'),
      dataIndex: '_RoomClass',
      width: 100,
    },
    {
      title: translateTextI18N('Front Office Status'),
      dataIndex: '_FrontOfficeStatus',
      width: 100,
    },
    {
      title: translateTextI18N('Occupancy Condition'),
      dataIndex: '_OccupancyCondition',
      width: 100,
    },
    {
      title: translateTextI18N('House Keeping Status'),
      dataIndex: '_HouseKeepingStatus',
      width: 100,
    },
  ];
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                onClick={fetchRoomData}
                title='Rooms'
                breadcrumb={['Department Admin', 'Front Desk', 'PMS', 'Rooms']}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='tablefilter-wrp'>
                <div className='form-row'>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        format={dateFormatList}
                        value={startDate}
                        placeholder={translateTextI18N('Start Date')}
                        onChange={e => setStartDate(e)}
                      />
                    </div>
                  </div>
                  <div className='col-6 col-md-4 col-lg'>
                    <div className='cmnSelect-form'>
                      <DatePicker
                        format={dateFormatList}
                        value={endDate}
                        placeholder={translateTextI18N('End Date')}
                        onChange={e => setEndDate(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={roomData?.RoomStatus}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey='_RoomNumber'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PMSrooms;