import React, { useState } from 'react'
import { Table, Button, Input } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import Axios from '../../../../utility/axiosHelper'
const GuestProfile = () => {
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const [searchValue, setSearchValue] = useState('')
  const [data, setData] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const ProfileData = async () => {
    setShowLoader(true)
    try {
      const response = await Axios.post(
        'http://localhost:8080/api/v1/pms/fetchProfile',
        { searchValue }
      )
      setData(response)
    } catch (error) {
      console.error('Error fetching data:', error)
      setErrorMessage('Failed to fetch data')
    } finally {
      setShowLoader(false)
    }
  }
  const fetchInvoiceData = async () => {
    setLoading(true)
    try {
      const response = await Axios.post(
        'http://localhost:8080/api/v1/pms/invoice',
        { searchValue }
      )
      setData(response)
    } catch (error) {
      console.error('Error fetching data:', error)
      setError('Failed to fetch data')
    } finally {
      setLoading(false)
    }
  }
  const handleClick = () => {
    fetchInvoiceData()
  }
  const columns = Object.keys(data).map(key => ({
    title: translateTextI18N(key),
    dataIndex: key,
    width: 150,
  }))
  const handleSearch = () => {
    console.log('Search value:', searchValue)
    ProfileData()
  }
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Guest Profile'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Guest Profile',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2 justify-content-start'>
                <div className='col-auto'>
                  <Input
                    placeholder='Search Profile ID'
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    style={{
                      width: '60%',
                      borderRadius: '4px',
                      marginRight: '10px',
                    }}
                  />
                  <Button
                    onClick={handleSearch}
                    type='primary'
                    style={{ borderRadius: '4px' }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='row' id='frontDeskData'>
            <div className='col-12 col-xl-12'>
              <div className='table-wrp'>
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={data ? [data] : []} // Wrap data in an array
                  scroll={{ y: 580 }}
                  loading={showLoader}
                  locale={{
                    emptyText: errorMessage || 'No data available',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default GuestProfile