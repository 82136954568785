/* eslint-disable import/no-anonymous-default-export */
export default [
  { id: 'American', name: 'American' },
  { id: 'African', name: 'African' },
  { id: 'Afghan', name: 'Afghan' },
  { id: 'French', name: 'French' },
  { id: 'Brunch', name: 'Brunch' },
  { id: 'Sea Food', name: 'Sea Food' },
  { id: 'Thai', name: 'Thai' },
  { id: 'Chinese', name: 'Chinese' },
  { id: 'Korean', name: 'Korean' },
  { id: 'Japanese', name: 'Japanese' },
  { id: 'Asian', name: 'Asian' },
  { id: 'Fusion', name: 'Fusion' },
  { id: 'Indian', name: 'Indian' },
  { id: 'Oriental', name: 'Oriental' },
  { id: 'Middle Eastern', name: 'Middle Eastern' },
  { id: 'Mediterranean', name: 'Mediterranean' },
  { id: 'Pastry', name: 'Pastry' },
  { id: 'European', name: 'European' },
  { id: 'English', name: 'English' },
  { id: 'Spanish', name: 'Spanish' },
  { id: 'Terrace', name: 'Terrace' },
  { id: 'Outdoor', name: 'Outdoor' },
  { id: 'Poolside', name: 'Poolside' },
  { id: 'Coffee Shop', name: 'Coffee Shop' },
  { id: 'Sky Lounge', name: 'Sky Lounge' },
  { id: 'Lobby Lounge', name: 'Lobby Lounge' },
  { id: 'Grill', name: 'Grill' },
  { id: 'Bar', name: 'Bar' },
  { id: 'Pub', name: 'Pub' },
  { id: 'Shisha Lounge', name: 'Shisha Lounge' },
  { id: 'Art Cafe', name: 'Art Cafe' },
  { id: 'Ala Carte', name: 'Ala Carte' },
  { id: 'Arabic', name: 'Arabic' },
  { id: 'Tea Lounge', name: 'Tea Lounge' },
  { id: 'Italian', name: 'Italian' },
  { id: 'Street Food', name: 'Street Food' },
  { id: 'Singaporean', name: 'Singaporean' },
  { id: 'Turkish', name: 'Turkish' },
  { id: 'Tandoor', name: 'Tandoor' },
  { id: 'Iranian', name: 'Iranian' },
  { id: 'Pakistan', name: 'Pakistan' },
  { id: 'Russian', name: 'Russian' },
  { id: 'Contemporary', name: 'Contemporary' },
  { id: 'International', name: 'International' },
  { id: 'Signature', name: 'Signature' },
  { id: 'Water Front', name: 'Water Front' },
  { id: 'Desserts', name: 'Desserts' },
  { id: 'Multi Cuisine', name: 'Multi Cuisine' },
  { id: 'Lobby Cafe', name: 'Lobby Cafe' },
  { id: 'Casual', name: 'Casual' },
  { id: 'Pattisserie', name: 'Pattisserie' },
  { id: 'Latin American', name: 'Latin American' },
  { id: 'lebanese', name: 'lebanese' },
  { id: 'lounge', name: 'lounge' },
  { id: 'Penthouse', name: 'Penthouse' },
  { id: 'Healthy', name: 'Healthy' },
  { id: 'Music Lounge', name: 'Music Lounge' },
  { id: 'Steak', name: 'Steak' },
  { id: 'Other', name: 'Other' },
  { id: 'Mexican', name: 'Mexican' },
  { id: 'Vegan', name: 'Vegan' },
  { id: 'Vegetarian', name: 'Vegetarian' },
  { id: 'Persian', name: 'Persian' },
]
