import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Modal, Spin } from 'antd'
import 'antd/dist/antd.css'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import Axios from '../../../../utility/axiosHelper'
const Invoice = () => {
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  const [searchValue, setSearchValue] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const fetchInvoiceData = async () => {
    setLoading(true)
    try {
      const response = await Axios.post(
        'http://localhost:8080/api/v1/pms/invoice',{ searchValue })
      setData(response)
    } catch (error) {
      console.error('Error fetching data:', error)
      setError('Failed to fetch data')
    } finally {
      setLoading(false)
    }
  }
  const handleClick = () => {
    fetchInvoiceData()
  }
  const columns = [
    {
      title: translateTextI18N('First name'),
      dataIndex: 'firstName',
      width: 100,
    },
    {
      title: translateTextI18N('Last name'),
      dataIndex: 'lastName',
      width: 100,
    },
    {
      title: translateTextI18N('Profile ID'),
      dataIndex: 'profileIDs',
      width: 100,
    },
    {
      title: translateTextI18N('Address'),
      dataIndex: 'address',
      width: 100,
    },
    {
      title: translateTextI18N('Bill Number'),
      dataIndex: 'billNumber',
      width: 100,
    },
    {
      title: translateTextI18N('Total Amount'),
      dataIndex: '',
      width: 100,
      render: (_, row) => {
        let totalAmount = row.billItems.reduce(
          (sum, item) => sum + parseFloat(item.amount),
          0
        )
        totalAmount = (totalAmount + parseFloat(row.currentBalance)).toFixed(2)
        return totalAmount.toString()
      },
    },
    {
      title: translateTextI18N('Detail'),
      dataIndex: 'detail',
      width: 100,
      render: (_, row) => (
        <a
          className='viewlink'
          onClick={() => {
            setSelectedOrder(row)
            setIsModalVisible(true)
          }}
        >
          {translateTextI18N('View')}
        </a>
      ),
    },
  ]
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Invoice'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Invoice',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2 justify-content-start'>
                <div className='col-auto'>
                  <Input
                    placeholder='Search Invoice'
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    style={{
                      width: '60%',
                      borderRadius: '4px',
                      marginRight: '10px',
                    }}
                  />
                  <Button
                    onClick={handleClick}
                    type='primary'
                    style={{
                      borderRadius: '5px',
                      padding: '2px 20px',
                      margin: '5px',
                    }}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='row' id='frontDeskData'>
            <div className='col-12 col-xl-12'>
              <div className='table-wrp'>
                <Spin spinning={loading}>
                  <Table
                    columns={columns}
                    dataSource={data ? [data] : []}
                    loading={showLoader}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={translateTextI18N('Invoice Details')}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        className='orderdetailsModal room-service-modal cmnModal'
        footer={null}
        centered
      >
        {selectedOrder && (
          <div className='orderdetails-wrp'>
            <div className='orderpersonDetails'>
              <h4>{`${selectedOrder.firstName} ${selectedOrder.lastName}`}</h4>
              <h6>
                {translateTextI18N('Profile ID')}: {selectedOrder.profileIDs}
              </h6>
              <h6>
                {translateTextI18N('Address')}: {selectedOrder.address}
              </h6>
              <h6>
                {translateTextI18N('Bill Number')}: {selectedOrder.billNumber}
              </h6>
              <h6>
                {translateTextI18N('Current Balance')}:{' '}
                {selectedOrder.currentBalance}
              </h6>
            </div>
            <div className='invoicebillTable'>
              <table>
                <thead>
                  <tr>
                    <th>{translateTextI18N('Date')}</th>
                    <th>{translateTextI18N('Transaction No')}</th>
                    <th>{translateTextI18N('Description')}</th>
                    <th>{translateTextI18N('Amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.billItems.map((item, index) => (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>{item.transactionNo}</td>
                      <td>{item.description}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='invoicebillTable'>
              <table>
                <thead>
                  <tr>
                    <th>{translateTextI18N('Description')}</th>
                    <th>{translateTextI18N('VAT Amount')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.billTaxes.map((tax, index) => (
                    <tr key={index}>
                      <td>{tax.description}</td>
                      <td>{tax.vatAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
export default Invoice