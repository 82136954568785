import React from 'react'

const ThemeIcon = () => {
  return (
    <>
      <div className='icon-wrp'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='13.417'
          height='13.416'
          viewBox='0 0 13.417 13.416'
        >
          <g id='theme' transform='translate(0 0)'>
            <path
              className='fillcss'
              id='Path_31998'
              data-name='Path 31998'
              d='M4.025,12.726a2.342,2.342,0,0,0,.691-1.667A2.741,2.741,0,0,1,6.265,9.314c.226-.094.535-.167.72.018l.215.215,0,0a1.576,1.576,0,0,0,2.224,0L13.3,5.674a.393.393,0,0,0,0-.556l-5-5a.393.393,0,0,0-.556,0L3.867,3.991a1.576,1.576,0,0,0,0,2.224l.217.217a.4.4,0,0,1,.115.3A2.652,2.652,0,0,1,2.358,8.7,2.361,2.361,0,0,0,0,11.059H0a2.357,2.357,0,0,0,4.025,1.667Zm4-11.776L12.468,5.4,10.8,7.064,6.353,2.617ZM1.247,12.17A1.571,1.571,0,0,1,2.358,9.486a2.667,2.667,0,0,0,1.718-.93,2.958,2.958,0,0,0,.91-1.821,1.187,1.187,0,0,0-.345-.859l-.217-.217a.79.79,0,0,1,0-1.112L5.8,3.173,10.244,7.62,8.87,8.994a.79.79,0,0,1-1.112,0l-.217-.217a1.372,1.372,0,0,0-1.577-.188,3.41,3.41,0,0,0-2.034,2.47A1.571,1.571,0,0,1,1.247,12.17Z'
              transform='translate(0 0)'
            />
            <ellipse
              className='fillcss'
              id='Ellipse_607'
              data-name='Ellipse 607'
              cx='0.596'
              cy='0.298'
              rx='0.596'
              ry='0.298'
              transform='translate(1.788 10.728)'
            />
          </g>
        </svg>
      </div>
    </>
  )
}

export default ThemeIcon
