import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DatePicker, Table, Button, message } from 'antd'
import { ReservationCollectionListener } from '../../../../services/pms'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import {
  PaginationOptions,
  pendingLable,
  rejectedLable,
  Collections,
  APIs,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import { render } from '@testing-library/react'
import axios from 'axios'
const PMSrooms = () => {
  const [data, setData] = useState(null)
  const { hotelId, userInfo } = useContext(AuthContext)
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [translateTextI18N] = useCustomI18NTranslatorHook()
  useEffect(() => {
    fetchKioskData()
    return () => {
      // Optional: Cleanup if needed
    }
  }, [])
  const soapRequest = ``
  const fetchKioskData = async () => {
    setShowLoader(true)
    try {
      const response = await fetch(
        'http://localhost:8080/api/v1/pms/kioskAvailability',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'text/xml;charset=UTF-8',
          },
          body: soapRequest,
        }
      )
      const responseBody = await response.json()
      console.log('Response:', responseBody)
      setData(responseBody)
      setSuccessMessage('Data fetched successfully')
    } catch (error) {
      console.error('Error fetching data:', error)
      setErrorMessage('Failed to fetch data')
    } finally {
      setShowLoader(false)
    }
  }
  const columns = [
    {
      title: translateTextI18N('Status'),
      dataIndex: 'Status',
      width: 100,
    },
    {
      title: translateTextI18N('Rate Plan Code'),
      dataIndex: 'RatePlanCode',
      width: 100,
    },
    {
      title: translateTextI18N('Rate Plan Description'),
      dataIndex: 'RatePlanDescription',
      width: 100,
    },
    {
      title: translateTextI18N('Room Type Code'),
      dataIndex: 'RoomTypeCode',
      width: 100,
    },
    {
      title: translateTextI18N('Number Of Units'),
      dataIndex: 'NumberOfUnits',
      width: 100,
    },
    {
      title: translateTextI18N('Room Type Description'),
      dataIndex: 'RoomTypeDescription',
      width: 100,
    },
    {
      title: translateTextI18N('First Day Amount'),
      dataIndex: 'FirstDayAmount',
      width: 100,
    },
    {
      title: translateTextI18N('Total Stay Amount'),
      dataIndex: 'TotalStayAmount',
      width: 100,
    },
  ]
  return (
    <>
      <Header></Header>
      <SideMenu></SideMenu>
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='Kiosk Availability'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Kiosk Availability',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon={true}
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon={true}
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={data}
                      loading={showLoader}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      rowKey=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default PMSrooms