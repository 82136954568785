import React, { useState, useEffect, useCallback } from 'react'
import { Popover, Button, Badge } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AddNotificationsListener,
  UpdateNotification,
} from '../../../services/notification'
import { GetCurrentUser } from '../../../services/user'
import { FormatTimeago, getImage } from '../../../config/utils'
import { actions } from '../../../Store'
import {
  DEPARTMENT_REDIRECTION,
  ADMIN_REDIRECTION,
  notificationTypes,
} from '../../../config/constants'
import DepartmentAndServiceKeys from '../../../config/departmentAndServicekeys'
import { db } from '../../../config/firebase'

const NotificationItem = ({ element, defaultRequestType, openMenu }) => (
  <li
    onClick={() => openMenu(element)}
    className={element.readStatus ? '' : 'new'}
    style={{ cursor: 'pointer' }}
  >
    <div className='notificationtext'>
      {element.requestType && defaultRequestType[element.requestType]}
      <div>{ReactHtmlParser(element.message)}</div>
      <span>{FormatTimeago(element.createdAt)}</span>
    </div>
  </li>
)

const loadCollectionFromPath = async path => {
  try {
    const segments = path.split('/')
    const isDocument = segments.length % 2 === 0

    if (isDocument) {
      const docSnapshot = await db.doc(path).get()
      return docSnapshot.exists
        ? { id: docSnapshot.id, ...docSnapshot.data() }
        : null
    } else {
      const querySnapshot = await db.collection(path).get()
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    }
  } catch (error) {
    console.error('Error loading from Firestore:', error)
    throw error
  }
}

const getServiceType = (
  element,
  departmentAndServiceIdToInfo,
  servicesNew,
  requestDocument
) => {
  if (!element.requestPath) return element.serviceType
  const [, , , deptId] = element.requestPath.split('/')
  const deptKey = departmentAndServiceIdToInfo[deptId]?.key
  let service = servicesNew?.[deptId]?.find(i => i.name === element.serviceType)
  if (!service) {
    const serviceTypeFromRequest = requestDocument?.service
    if (serviceTypeFromRequest === 'COMP / CHRG') {
      return serviceTypeFromRequest
    }
    if (serviceTypeFromRequest === 'Maintenance') {
      return serviceTypeFromRequest
    }
    if (serviceTypeFromRequest === 'Replacement') {
      return serviceTypeFromRequest
    }
    console.log('serviceTypeFromRequest', serviceTypeFromRequest)

    service = servicesNew?.[deptId]?.find(
      i => i.name === serviceTypeFromRequest
    )
  }
  if (!service && deptKey !== 'front-desk') return 'more-request'
  const { frontDesk, houseKeeping } = DepartmentAndServiceKeys
  if (frontDesk.key === deptKey) {
    return !service?.frontDeskServiceType ||
      service.frontDeskServiceType === frontDesk.type.OtherRequest
      ? 'other'
      : element.serviceType
  } else if (houseKeeping.key === deptKey) {
    return !service.default ? 'housekeeping-morerequest' : element.serviceType
  } else {
    return !service?.default ? 'more-request' : element.serviceType
  }
}

const Notifications = ({ isHotelAdmin, userInfo = {} }) => {
  const {
    notifications,
    sideMenuOpenKeys,
    departmentAndServiceIdToInfo,
    servicesNew,
    isCustomDepartmentLogin,
    hotelInfo,
  } = useSelector(state => state)
  const [notificationCount, setNotificationCount] = useState(0)

  const dispatch = useDispatch()
  const history = useHistory()

  const userid = GetCurrentUser()?.uid

  useEffect(() => {
    if (userid && hotelInfo.hotelId) {
      AddNotificationsListener(userid, hotelInfo.hotelId, dispatch)
    }
  }, [dispatch, userid, hotelInfo.hotelId])

  useEffect(() => {
    setNotificationCount(
      notifications.filter(request => !request.readStatus).length
    )
  }, [notifications])

  const getRedirection = useCallback(
    async element => {
      const currentUserDepartmentId = userInfo?.departmentId
      const requestPath = element?.requestPath
      const notificationType = element?.notificationType?.toLowerCase()
      console.log(element)

      if (['escalation', 'warning'].includes(notificationType)) return null

      if (element?.title === 'CHECKIN') {
        return DEPARTMENT_REDIRECTION[element?.title.toLowerCase()]
      }

      if (!requestPath && notificationType != 'guest_feedback') return null
      let targetPart, documents
      if (requestPath) {
        [, , , targetPart] = requestPath.split('/')
        documents = await loadCollectionFromPath(requestPath)
      }
      console.log('sdfs')
      const fromDepartmentId = documents?.fromDepartmentId

      
      if (isHotelAdmin) {
        if (notificationType === 'deferred') {
          if (documents?.isGuestRequest) {
            return ADMIN_REDIRECTION['department_request']
          } else {
            return ADMIN_REDIRECTION['guest_request']
          }
        }
        console.log('element', element)
        console.log('documents', documents)


        if(documents.department === 'Food and Beverage'){

          return ADMIN_REDIRECTION[element.serviceType.toLowerCase()]
        }
        return notificationType === 'guest_feedback'
          ? ADMIN_REDIRECTION['checkin']
          : ADMIN_REDIRECTION[element.notificationType.toLowerCase()]
      }

      if (targetPart === currentUserDepartmentId) {
        if (
          element?.serviceType === 'Change Room' ||
          element?.serviceType === 'Upgrade Room'
        ) {
          return DEPARTMENT_REDIRECTION['change/upgrade room']
        }
        if (documents?.department === 'Engineering & Maintenance') {
          if (documents?.isGuestRequest) {
            return currentUserDepartmentId === fromDepartmentId
              ? DEPARTMENT_REDIRECTION['FrontDeskGuestRequest']
              : DEPARTMENT_REDIRECTION['GUEST_REQUEST']
          } else {
            return currentUserDepartmentId === fromDepartmentId
              ? DEPARTMENT_REDIRECTION['FrontDeskDepartmentRequest']
              : DEPARTMENT_REDIRECTION['DEPARTMENT_REQUEST']
          }
        }
        const serviceType = getServiceType(
          element,
          departmentAndServiceIdToInfo,
          servicesNew,
          documents
        )
        console.log('serviceType', serviceType)

        return DEPARTMENT_REDIRECTION[serviceType.toLowerCase()]
      }
      if (documents?.isGuestRequest) {
        return currentUserDepartmentId === fromDepartmentId
          ? DEPARTMENT_REDIRECTION['FrontDeskGuestRequest']
          : DEPARTMENT_REDIRECTION['GUEST_REQUEST']
      } else {
        return currentUserDepartmentId === fromDepartmentId
          ? DEPARTMENT_REDIRECTION['FrontDeskDepartmentRequest']
          : DEPARTMENT_REDIRECTION['DEPARTMENT_REQUEST']
      }
    },
    [isHotelAdmin, userInfo, departmentAndServiceIdToInfo, servicesNew]
  )

  const openMenu = useCallback(
    async element => {
      if (!element.readStatus) {
        UpdateNotification(element.id, true, userid)
      }

      const redirection = await getRedirection(element)
      if (!redirection) return

      if (isHotelAdmin && !sideMenuOpenKeys?.includes('sub2')) {
        dispatch(actions.setSideMenuOpenKeys('sub2'))
      }

      console.log('redirection', redirection)

      history.push(redirection)
      dispatch(actions.setCurrentroute(redirection))
    },
    [getRedirection, isHotelAdmin, sideMenuOpenKeys, dispatch, history, userid]
  )

  const defaultRequestType = {
    Urgent: <div className='urgentatg'>Urgent</div>,
    Escalation: <div className='escalationtag'>Escalated</div>,
    Warning: <div className='warningtag'>Warning</div>,
    Deferred: <div className='deferredtag'>Deferred</div>,
    Canceled: <div className='canceledtag'>Canceled</div>,
    Jobreturn: <div className='jobreturntag'>Jobreturn</div>,
    Reminder: <div className='remindertag'>Reminder</div>,
  }

  const notificationContent = (
    <ul className='list-unstyled'>
      {notifications.map((element, index) => (
        <NotificationItem
          key={index}
          element={element}
          defaultRequestType={defaultRequestType}
          openMenu={openMenu}
        />
      ))}
    </ul>
  )

  const NotificationBadge = () => (
    <div className='notification-wrp'>
      <Badge
        count={notificationCount}
        size='small'
        offset={notificationCount < 10 ? [2, 0] : [4, 0]}
      >
        <Popover
          className='notificationPop'
          overlayClassName='notificationDropdown'
          trigger='click'
          placement='bottomLeft'
          content={notificationContent}
          getPopupContainer={trigger => trigger.parentElement}
        >
          <Button>
            {isHotelAdmin ? (
              <svg
                className='headericonsvg'
                xmlns='https://www.w3.org/2000/svg'
                width='16.574'
                height='19.888'
                viewBox='0 0 16.574 19.888'
              >
                <g id='bell_1_' data-name='bell (1)' transform='translate(-2)'>
                  <path
                    id='Path_31904'
                    data-name='Path 31904'
                    d='M18.059,14.015a5.55,5.55,0,0,1-1.971-4.247V7.458a5.805,5.805,0,0,0-4.972-5.734V.829a.829.829,0,1,0-1.657,0v.895A5.8,5.8,0,0,0,4.486,7.458v2.31a5.557,5.557,0,0,1-1.979,4.254,1.45,1.45,0,0,0,.943,2.551H17.123a1.45,1.45,0,0,0,.936-2.558Z'
                    fill='#ffffff'
                  />
                  <path
                    id='Path_31905'
                    data-name='Path 31905'
                    d='M11.371,23.486A3.112,3.112,0,0,0,14.415,21H8.326A3.112,3.112,0,0,0,11.371,23.486Z'
                    transform='translate(-1.084 -3.598)'
                    fill='#ffffff'
                  />
                </g>
              </svg>
            ) : (
              <img src={getImage('images/bellicon.svg')} alt='Notification' />
            )}
          </Button>
        </Popover>
      </Badge>
    </div>
  )

  if (!isHotelAdmin && !userInfo?.isDepartmentAdmin) return null

  return <NotificationBadge />
}

export default Notifications
