import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Spin, Select } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import PageNamecard from '../../../Common/PageNameCard/PageNameCard'
import { db } from '../../../../config/firebase'
import { Collections } from '../../../../config/constants'
import { useSelector } from 'react-redux'
const PmsConfiguration = () => {
  const [userName, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [domain, setDomain] = useState('')
  const [emailTimePeriod, setEmailTimePeriod] = useState('')
  const [customTime, setCustomTime] = useState('')
  const [loading, setLoading] = useState(true)
  let { hotelInfo } = useSelector(state => state)
  const hotelId = hotelInfo.hotelId
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = db.collection(Collections.HOTELS).doc(hotelId)
        const doc = await docRef.get()
        if (doc.exists) {
          const data = doc.data()
          setUsername(data.pmsUserName || '')
          setPassword(data.pmsPassword || '')
          setDomain(data.pmsDomain || '')
          setEmailTimePeriod(data.emailTimePeriod || '')
          setCustomTime(data.customTime || '')
        } else {
          console.log('No such document!')
        }
      } catch (error) {
        console.log('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [hotelId])
  const handleClick = async () => {
    try {
      setLoading(true)
      const docRef = db.collection(Collections.HOTELS).doc(hotelId)
      await docRef.set(
        {
          pmsUserName: userName,
          pmsPassword: password,
          pmsDomain: domain,
          emailTimePeriod: emailTimePeriod,
          customTime: emailTimePeriod === 'custom' ? customTime : null,
        },
        { merge: true }
      )
      setLoading(false)
      return { success: true, message: 'Document updated successfully' }
    } catch (error) {
      console.log({ error })
      console.log(error?.message)
      return { success: false, message: error?.message }
    }
  }
  const calculateHours = (days) => days * 24
  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNamecard
                title='PMS Configuration'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'PMS Configuration',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              {loading ? (
                <div className='row ml-2 mb-2 justify-content-center'>
                  <Spin tip='Updating...' />
                </div>
              ) : (
                <div className='row ml-2 mb-2 justify-content-start'>
                  <div className='col-auto'>
                    <Form
                      layout='vertical'
                      onFinish={handleClick}
                      initialValues={{
                        pmsUserName: userName,
                        pmsPassword: password,
                        pmsDomain: domain,
                        emailTimePeriod: emailTimePeriod,
                      }}
                    >
                      <Form.Item
                        name='pmsUserName'
                        label='Username'
                        rules={[
                          { required: true, message: 'Username is required' },
                        ]}
                      >
                        <Input
                          placeholder='PMS UserName'
                          value={userName}
                          onChange={e => setUsername(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name='pmsPassword'
                        label='Password'
                        rules={[
                          {
                            required: true,
                            message: 'Password is required',
                          },
                        ]}
                      >
                        <Input
                          placeholder='PMS UserPassword'
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name='pmsDomain'
                        label='Domain'
                        rules={[
                          { required: true, message: 'Domain is required' },
                        ]}
                      >
                        <Input
                          placeholder='PMS Domain'
                          value={domain}
                          onChange={e => setDomain(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name='emailTimePeriod'
                        label='Pre-Check-In Automatic Email Dispatch Schedule'
                        rules={[
                          {
                            required: true,
                            message: 'Email schedule is required',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Select Schedule'
                          value={emailTimePeriod}
                          onChange={value => {
                            setEmailTimePeriod(value)
                            if (value !== 'custom') {
                              setCustomTime('')
                            }
                          }}
                        >
                        
                          <Select.Option value={calculateHours(30)}>
                            1 month before arrival
                          </Select.Option>
                          <Select.Option value={calculateHours(7)}>
                            1 week before arrival
                          </Select.Option>
                          <Select.Option value={calculateHours(1)}>
                            1 day before arrival
                          </Select.Option>
                          <Select.Option value={calculateHours(0)}>
                            On arrival day
                          </Select.Option>
                          <Select.Option value='custom'>
                            Custom Timing
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      {emailTimePeriod === 'custom' && (
                        <Form.Item
                          name='customTime'
                          label='Custom Timing'
                          rules={[
                            {
                              required: true,
                              message: 'Custom time is required',
                            },
                          ]}
                        >
                          <Input
                            placeholder='e.g., 5 days before arrival'
                            value={customTime}
                            onChange={e => setCustomTime(e.target.value)}
                          />
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Button htmlType='submit' type='primary'>
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default PmsConfiguration