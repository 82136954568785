import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Table, Button, message, Modal, Input } from 'antd'
import Header from '../../../Common/Header/Header'
import SideMenu from '../../../Common/Sidemenu/Sidemenu'
import { useDispatch, useSelector } from 'react-redux'
import {
  AddGuestListener,
  AcceptRejectCheckIn,
} from '../../../../services/guest'
import PageNameCard from '../../../Common/PageNameCard/PageNameCard'
import { GetCurrentUser } from '../../../../services/user'
import {
  PaginationOptions,
  secondsToShowAlert,
  APIs,
} from '../../../../config/constants'
import CustomAlert from '../../../Common/CustomAlert/CustomAlert'
import { useCustomI18NTranslatorHook } from '../../../../utility/globalization'
import { AdminRequest } from '../../../../services/notification'
import { AuthContext } from '../../../../Router/AuthRouteProvider'
import { getCommonColumns } from '../../../../config/utils'
import {
  UpdateGuestRoomNumber,
  sendEmailByEmailId,
  preCheckinRequestCollectionListener,
  unsubscribePreCheckinRequests,
} from '../../../../services/pms'
import Axios from '../../../../utility/axiosHelper'
import axios from 'axios'
import { auth, db, firebase } from '../../../../config/firebase'

const PMSAssignRooms = () => {
  const { hotelId, userInfo } = useContext(AuthContext)
  const dispatch = useDispatch()
  const [translateTextI18N] = useCustomI18NTranslatorHook()

  // State management
  const [showLoader, setShowLoader] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRoomNumber, setSelectedRoomNumber] = useState('')
  const [selectedGuest, setSelectedGuest] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const preCheckinRequestsData = useSelector(state => state.preCheckinRequests)
  const [isIdImageModalVisible, setIsIdImageModalVisible] = useState(false)
  const [selectedIdImage, setSelectedIdImage] = useState(null)
  const { hotelInfo } = useSelector(state => state)

  // Effects
  useEffect(() => {
    const pmsType = hotelInfo?.pmsType
    AddGuestListener(hotelId, dispatch)
    preCheckinRequestCollectionListener(hotelId, pmsType, dispatch)
    return () => {
      unsubscribePreCheckinRequests(hotelId)
    }
  }, [dispatch, hotelId])

  useEffect(() => {
    const data = preCheckinRequestsData.filter(
      item =>
        item.source === 'pms' &&
        (item.status === 'Accepted' || item.status === 'Check In')
    )
    setFilteredData(data)
  }, [preCheckinRequestsData])

  // Utility functions
  const setTemporaryMessage = (setMessageFunc, messageText) => {
    setMessageFunc(translateTextI18N(messageText))
    setTimeout(() => setMessageFunc(''), secondsToShowAlert)
  }

  const handleRoomAssignment = useCallback(async () => {
    try {
      if (!selectedGuest) return

      setShowLoader(true)
      const { success, message: responseMessage } = await AcceptRejectCheckIn(
        hotelId,
        selectedGuest.id,
        true,
        selectedRoomNumber,
        preCheckinRequestsData
      )

      if (success) {
        const userId = GetCurrentUser().uid
        // AdminRequest has been commented out as requested

        setTemporaryMessage(
          setSuccessMessage,
          'Guest check in accepted successfully'
        )
      } else {
        setTemporaryMessage(setErrorMessage, responseMessage)
      }
    } catch (error) {
      message.error('Something went wrong! Please try again!')
    } finally {
      setShowLoader(false)
      setIsModalVisible(false)
    }
  }, [hotelId, selectedGuest, selectedRoomNumber, userInfo])

  const handleUpdateRoomNumber = useCallback(async () => {
    try {
      setShowLoader(true)
      if (!selectedRoomNumber || !selectedGuest) {
        message.error('Room number and guest information are required')
        return
      }

      await UpdateGuestRoomNumber(selectedGuest.guestId, selectedRoomNumber)

      // Call the backend API to generate the custom token using axios
      const response = await axios.get(`${APIs.GUEST_WIFI_ONBOARDING}`, {
        params: {
          operation: 'generateToken',
          property: hotelInfo?.domain,
          guestId: selectedGuest?.guestId,
          hotelId: hotelInfo?.hotelId,
          // hex: '',
        },
      })

      const data = response.data
      if (!data.success) {
        throw new Error('Failed to generate custom token for guest')
      }

      // const guestAppLink = data.clientURL
      const token = data?.token
      // console.log('guestAppLink', guestAppLink)

      // Construct the link with the custom token
      const guestAppLink = `https://dev-guest.inplass.online/bookingreference/?token=${token}`

      console.log('guestAppLink', guestAppLink)
      const emailVariables = {
        '%firstName%': selectedGuest?.name || '',
        '%lastName%': selectedGuest?.surName || '',
        '%link%': guestAppLink,
        '%roomNumber%': selectedRoomNumber,
        '%bookingReferance%': selectedGuest.bookingReferance,
      }
      const emailResponse = await sendEmailByEmailId(
        selectedGuest.email,
        emailVariables,
        'assignRoom',
        hotelId
      )

      if (emailResponse.success) {
        message.success('Room assigned and email sent successfully')
      } else {
        message.warning('Room assigned but failed to send email')
      }
      setShowLoader(false)
      setIsModalVisible(false)

      await handleRoomAssignment()
    } catch (error) {
      console.error('Error in room assignment:', error)
      message.error('Failed to update room number')
    }
  }, [selectedGuest, selectedRoomNumber, hotelId])

  const handleIdImageView = idImageUrl => {
    console.log('idImageUrl', idImageUrl)
    setSelectedIdImage(idImageUrl)
    setIsIdImageModalVisible(true)
  }

  // Table configuration
  const { bookingReferanceCol, roomNumberCol, submittedTimeCol } =
    getCommonColumns({ translateTextI18N })

  const columns = [
    {
      title: 'Sl.No',
      dataIndex: 'index',
      width: 100,
      render: (_, __, index) => index + 1,
    },
    {
      title: translateTextI18N('Guest Name'),
      dataIndex: 'guestName',
      width: 170,
    },
    {
      title: translateTextI18N('Room Number'),
      dataIndex: 'roomNumber',
      width: 100,
    },
    {
      ...bookingReferanceCol,
      width: 130,
    },
    // {
    //   title: translateTextI18N('Id Number'),
    //   dataIndex: 'verificationIdNumber',
    //   width: 100,
    // },
    {
      title: translateTextI18N('ID Verification'),
      dataIndex: 'identification',
      width: 120,
      render: (_, row) => {
        return row.identification?.url ? (
          <Button
            type='primary'
            onClick={() => handleIdImageView(row.identification.url)}
          >
            View ID
          </Button>
        ) : (
          <span>No ID uploaded</span>
        )
      },
    },
    {
      title: translateTextI18N('Check-In Date'),
      dataIndex: 'checkin',
      width: 100,
    },
    {
      title: translateTextI18N('Check-In Time'),
      dataIndex: 'arrival',
      width: 100,
    },
    {
      title: translateTextI18N('Accompanying Guest'),
      dataIndex: 'additionalGuestName',
      width: 170,
    },
    submittedTimeCol,
    {
      title: translateTextI18N('Action'),
      width: 120,
      render: row => (
        <Button
          className='statusBtn completedBtn ml-2'
          onClick={() => {
            setIsModalVisible(true)
            setSelectedRoomNumber(row.roomNumber)
            setSelectedGuest(row)
          }}
        >
          {translateTextI18N('Assign Room')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <Header />
      <SideMenu />
      <section className='mainContent department-wrp' id='frontDeskMain'>
        <div className='mainContent-in'>
          <div className='row'>
            <div className='col-12'>
              <PageNameCard
                title='Assign Room'
                breadcrumb={[
                  'Department Admin',
                  'Front Desk',
                  'PMS',
                  'Assign Room',
                ]}
              />
            </div>
            <div className='col-12 col-xl-12'>
              <div className='row ml-2 mb-2' id='frontDeskAlerts'>
                <CustomAlert
                  visible={successMessage}
                  message={successMessage}
                  type='success'
                  showIcon
                />
                <CustomAlert
                  visible={errorMessage}
                  message={errorMessage}
                  type='error'
                  showIcon
                />
              </div>
              <div className='row' id='frontDeskData'>
                <div className='col-12 col-xl-12'>
                  <div className='table-wrp'>
                    <Table
                      columns={columns}
                      dataSource={filteredData}
                      pagination={PaginationOptions}
                      scroll={{ y: 580 }}
                      loading={showLoader}
                      rowKey='id'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title='Assign Room Number'
        visible={isModalVisible}
        onOk={handleUpdateRoomNumber}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          value={selectedRoomNumber}
          onChange={e => setSelectedRoomNumber(e.target.value)}
          placeholder='Enter room number'
          className='w-full'
        />
      </Modal>
      <Modal
        title='ID Verification'
        visible={isIdImageModalVisible}
        onCancel={() => setIsIdImageModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedIdImage && (
          <img
            src={selectedIdImage}
            alt='ID Verification'
            style={{ width: '100%' }}
          />
        )}
      </Modal>
    </>
  )
}

export default PMSAssignRooms
