import React from 'react'

function ChangePasswordIcon() {
  return (
    <>
      <div className='icon-wrp'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='15'
          viewBox='0 0 14 15'
          id='languageicon'
        >
          <path
            id='arrowtop'
            className='fillcss'
            d='M10.727,2c-2.1,0-3.818,1.446-3.818,3.214V7.357A1.746,1.746,0,0,0,5,8.964v3.214a4.458,4.458,0,0,0,1.655,3.429A6.326,6.326,0,0,0,10.727,17a5.614,5.614,0,0,0,5.6-3.75h.127a2.532,2.532,0,0,0,1.782-.643,1.968,1.968,0,0,0,.764-1.5,1.89,1.89,0,0,0-.764-1.5,2.942,2.942,0,0,0-1.782-.643,1.746,1.746,0,0,0-1.909-1.607V5.214C14.545,3.446,12.827,2,10.727,2Zm6.618,8.357a.928.928,0,0,1,0,1.5,1.6,1.6,0,0,1-1.336.268,1.07,1.07,0,0,1-.827-1.018.984.984,0,0,1,.382-.75,1.444,1.444,0,0,1,.509-.268.944.944,0,0,1,.382-.054A1.382,1.382,0,0,1,17.345,10.357ZM15.182,8.964v.321a3.547,3.547,0,0,0-.509.321,1.688,1.688,0,0,0-.636.964H8.182c-.382,0-.636.214-.636.536v1.071c0,.321.255.536.636.536s.636-.214.636-.536v-.536h1.273v.536c0,.321.255.536.636.536s.636-.214.636-.536v-.536h2.609a2.538,2.538,0,0,0,1.082,1.286,4.253,4.253,0,0,1-4.327,3,5.075,5.075,0,0,1-3.182-1.071,3.477,3.477,0,0,1-1.273-2.679V8.964c0-.321.255-.536.636-.536h7.636C14.927,8.429,15.182,8.643,15.182,8.964Zm-7-1.607V5.214a2.377,2.377,0,0,1,2.545-2.143,2.377,2.377,0,0,1,2.545,2.143V7.357Z'
            transform='translate(-5 -2)'
          />
        </svg>
      </div>
    </>
  )
}

export default ChangePasswordIcon
